import type { CSSProperties, PropsWithChildren } from "react";
import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import DropdownLink from "~/components/dropdown-link";

interface Props extends PropsWithChildren<unknown> {
  label: React.ReactNode;
  noCaret?: boolean;
  match?: boolean;
  options?: (string | (string | undefined)[])[];
  className?: string;
  name?: string;
  selected?: string;
  style?: CSSProperties;
  forwardRef?: React.ForwardedRef<HTMLLIElement>;
}

const NavDropdown = ({
  label,
  noCaret,
  options,
  className,
  name,
  selected,
  style,
  children,
  match,
  forwardRef
}: Props) => {
  const renderList = () => {
    if (options) {
      return options.map((o) => {
        const value = Array.isArray(o) ? o[1] : o;
        const label = Array.isArray(o) ? o[0] : o;
        return (
          <DropdownLink
            key={label}
            match={selected === value}
            to={{ append: { [name!]: value } }}
            label={label}
          />
        );
      });
    }

    return children;
  };

  return (
    <Menu
      as="li"
      className={clsx("dropdown", match && "active")}
      ref={forwardRef}
    >
      <MenuButton as="a" className="dropdown-toggle">
        {label}
        {!noCaret && (
          <span>
            {" "}
            <b className="caret" />
          </span>
        )}
      </MenuButton>
      <MenuItems
        modal={false}
        as="ul"
        className={clsx("dropdown-menu !block focus:outline-none", className)}
        style={style}
      >
        {renderList()}
      </MenuItems>
    </Menu>
  );
};

export default React.forwardRef<HTMLLIElement, PropsWithChildren<Props>>(
  (props, ref) => <NavDropdown forwardRef={ref} {...props} />
);
